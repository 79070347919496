import profile from 'assets/images/JerickIquin.webp';
import mainLogo from 'assets/logos/JI Scripts.webp';
import placeholder from 'assets/images/placeholder.webp';
import portfolio from 'assets/images/projects/portfolio.webp';
import arcade from 'assets/images/projects/virtual arcade.webp';
import jojo from 'assets/images/projects/jojos adventure.webp';
import brainflix from 'assets/images/projects/Brainflix.webp';
import weatherApp from 'assets/images/projects/simple weather app.webp';
import timer from 'assets/images/projects/multi timer.webp';

export const PROFILE = {
	src: profile,
	alt: 'Jerick Iquin - handsomest guy in his computer room'
};

export const LOGO = {
	src: mainLogo,
	alt: 'Jerick Iquin Scripts!'
};

export const PLACEHOLDER = {
	src: placeholder,
	alt: 'This is just a placeholder'
};

export const PROJECT_IMAGES = {
	portfolio,
	arcade,
	jojo,
	brainflix,
	weatherApp,
	timer
};
