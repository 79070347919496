export const emailRef = 'mailto:jci.dev.0@gmail.com?subject=Re: Portfoliov1';

export const SOCIALMEDIA = [
  {
    icon: 'fab fa-github',
    href: 'https://github.com/jekiquin',
  },
  {
    icon: 'fab fa-linkedin',
    href: 'https://www.linkedin.com/in/jerick-iquin/',
  },
  {
    icon: 'fas fa-envelope',
    href: emailRef,
  },
];
