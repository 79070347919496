export const NAVLIST = [
	{
		label: 'about',
		href: '#about'
	},
	{
		label: 'projects',
		href: '#projects'
	},
	{
		label: 'contact',
		href: '#contact'
	}
];
